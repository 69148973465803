.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


@keyframes boltAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}


.animate-bolt {
  animation: boltAnimation 2s linear infinite;
}

.vehicle-1 { background-color: #f0f8ff; }
.vehicle-2 { background-color: #faf0e6; }
.vehicle-3 { background-color: #e0ffff; }
.vehicle-4 { background-color: #f5f5dc; }
.vehicle-5 { background-color: #fffaf0; }
.vehicle-6 { background-color: #fff5ee; }
.vehicle-7 { background-color: #fdf5e6; }
.vehicle-8 { background-color: #e6e6fa; }
.vehicle-9 { background-color: #ffe4e1; }
.vehicle-10 { background-color: #fafad2; }
.vehicle-11 { background-color: #f0fff0; }
.vehicle-12 { background-color: #f5fffa; }
.vehicle-13 { background-color: #fff0f5; }
.vehicle-14 { background-color: #ffe4b5; }
.vehicle-15 { background-color: #fffacd; }
.vehicle-16 { background-color: #f8f8ff; }
.vehicle-17 { background-color: #faf0e6; }
.vehicle-18 { background-color: #e0ffff; }
.vehicle-19 { background-color: #f5f5dc; }
.vehicle-20 { background-color: #fffaf0; }
.vehicle-21 { background-color: #fff5ee; }
.vehicle-22 { background-color: #fdf5e6; }
.vehicle-23 { background-color: #e6e6fa; }
.vehicle-24 { background-color: #ffe4e1; }
.vehicle-25 { background-color: #fafad2; }
.vehicle-26 { background-color: #f0fff0; }
.vehicle-27 { background-color: #f5fffa; }
.vehicle-28 { background-color: #fff0f5; }
.vehicle-29 { background-color: #ffe4b5; }
.vehicle-30 { background-color: #fffacd; }
.vehicle-31 { background-color: #f8f8ff; }
.vehicle-32 { background-color: #faf0e6; }
.vehicle-33 { background-color: #e0ffff; }
.vehicle-34 { background-color: #f5f5dc; }
.vehicle-35 { background-color: #fffaf0; }
.vehicle-36 { background-color: #fff5ee; }
.vehicle-37 { background-color: #fdf5e6; }
.vehicle-38 { background-color: #e6e6fa; }
.vehicle-39 { background-color: #ffe4e1; }
.vehicle-40 { background-color: #fafad2; }

