.route-page-container {
	display: flex;

	width: 100%;
	height: 100vh;
}

.route-page {
	display:flex;
	flex: 4;
	flex-direction: column;
	padding: 30px;
	overflow: scroll;
}
